import React from 'react';
import request from '@helpers/request';
import dispatchEvent from '@helpers/dispatchEvent';
import $global, { updateGlobal } from '@effector/global';
import $order from '@effector/order';
import randomKey from '@helpers/randomkey';
import dayjs from 'dayjs';
import setClasses from '@helpers/setClasses';
import $detail from '@effector/detail';
import { combine } from 'effector';
import getParam from '@helpers/getParam';
import $address from '@effector/address';
import $cache, { updateCache } from '@effector/cache';
import parseString from '@helpers/parseString';
import ReactComponent from "@abstract/reactComponent";
import replaceAll from '../helpers/replaceAll';
import debug from '@helpers/debug';
import { $sortBy, $sort } from '@effector/sort';
import {config} from "@helpers/config";

const REGSTATE = /\$\$\$\${(.*?)}/g;
const REGSTORE = /\$\$\${(.*?)}/g;
const REGEXEC = /\$\${(.*?)}/g;
const REGVAR = /\${(.*?)}/g;
const REGALLDATA = /\${\*}/g
const REGGET = /\$GET{(.*?)}/g;
export const REGCONFIG = /\$CONFIG{(.*?)}/g;
const REGPACKAGE = /\$PACKAGE{(.*?)}/g;

export function ValueFromGet(value: string): string {
  let _value: string = value;
  if (!_value) return _value;
  if (!_value.replaceAll) return _value

  if (_value.search(REGGET) !== -1) {
    return _value.replaceAll(REGGET, (full: any, name: any) => {
      if (name) {
        let param: any = getParam(name);
        if (!param) return returnValueOrNull(param);
        return param
      }

      return full
    });
  }

  return '';
}
export function ValueFromConfig(value: string): string {
  let _value: string = value;
  if (!_value) return _value;
  if (!_value.replaceAll) return _value

  if (_value.search(REGCONFIG) !== -1) {
    let test = _value.replaceAll(REGCONFIG, (full: any, name: any) => {
      if (name) {
        let param: any = config[name];
        let ar = name.split('.');
        if (ar.length) {
          param = ConstructorElement.recurseSelect(ar, config)
        }

        if (param) return param
      }

      return full
    });

    return test
  }

  return value
}
export function ValueFromPackage(value: string): string {
  let _value: string = value;
  if (!_value) return _value;
  if (!_value.replaceAll) return _value

  const packagejson: any = require("@package.json")

  if (_value.search(REGPACKAGE) !== -1) {
    return _value.replaceAll(REGPACKAGE, (full: any, name: any) => {
      if (name) {
        let param: any = packagejson[name];
        if (!param) return returnValueOrNull(param);
        return param
      }

      return full
    });
  }

  return '';
}

function returnValueOrNull(val: any) {
  switch (val) {
    case "undefined":
    case undefined:
    case "null":
    case null:
      return null

    default:
      return val
  }
}

export interface requestInterface {
  url: string;
  cache?: boolean;
  eventName?: string
  headers?: any
  method: 'GET' | 'POST',
  params?: { name: string, value: string }[],
  body?: { name: string, value: string }[],
  required?: { name: string, value: string }[],
  abortController?: AbortController,
  str_par?: any,
  join?: any
}

interface ConditionProcInterface {
  path: string;
  operation: 'equal' | 'notequal' | 'larger' | 'less' | 'exist';
  operand: any;
}

export interface ShowErrorInterface {
  type: string | number
  value: any
}

export interface LogInterface extends requestInterface {
  debug?: Boolean
  debugParams?: { name: string, value: string, useValue?: boolean }[]
}

export interface ConstructorElementInterfaceP {
  onChange?: Function;
  observe?: any;
  condition?: any;
  name?: string;
  request?: requestInterface;
  requestSettings?: any;
  log?: LogInterface;
  getProps: Function;
  showErrors?: ShowErrorInterface[]
}

export interface ConstructorElementInterfaceS {
  isPreloader?: boolean;
  data?: any;
  error?: string;
  waiting?: boolean
  externalChanged: ReturnType<typeof setTimeout> | false
  observeValues: any
}

interface JoinElementInterface {
  root: string | undefined;
  type: 'fixed' | 'generator';
  data: any[] | any;
}

export class ConstructorElement<P extends ConstructorElementInterfaceP, S extends ConstructorElementInterfaceS = { observeValues: {}, waiting: false, externalChanged: false }> extends ReactComponent<P, S> {
  input: any;
  menuItemsRef: any
  listeners: any;
  helpers: any = {
    randomkey: () => randomKey(10),
    lastyear: () => dayjs(new Date()).subtract(1, 'year').format('YYYY-MM-DD'),
    today: () => dayjs(new Date()).format('YYYY-MM-DD'),
    tomorrow: () => dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD'),
    nextyear: () => dayjs(new Date()).add(1, 'year').format('YYYY-MM-DD'),
    datePlus: (fromName: string, countName: string) => {
      const from: any = this.state.observeValues[fromName];
      const count: number = this.parseValue(countName.replace('*', '}'), this.state.observeValues, {}, true)[0] - 1
      const date = from ? dayjs(from) : dayjs(new Date());
      return date.add(count, 'day').format('YYYY-MM-DD')
    },
    getActivePrice: (dataPath: string, idPath: string, keyPath: string, countPath: string, discountPath?: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, data]: [any, any] = this.parseValue(dataPath.replace('*', '}'), this.state.observeValues, {}, true);
      let [id] = this.parseValue(idPath.replace('*', '}'), this.state.observeValues, {}, true);
      id = parseString(id);
      if (!id) id = 0

      if (data && data[id]) {
        const price = this.goToRecursivePath(JSON.parse(keyPath), data[id]);
        const discountPrice = discountPath && this.goToRecursivePath(JSON.parse(discountPath), data[id]);
        let count = parseString(this.parseValue(countPath.replace('*', '}'), this.state.observeValues, {}, true)[0])
        if (!count) count = parseInt(countPath);
        if (isNaN(count)) count = data[id]?.count

        if (discountPrice) return discountPrice * count;

        return price * count;
      }

      return 0
    },
    toIsoDate: (date: string) => {
      let _date = date;
      try {
        _date = dayjs().toISOString()
      } catch (error) {

      }

      return _date
    },
    getPrice: (storeName: string, priceName: string, countName: string) => {
      let store = this.store.getState()[storeName]
      let price: number = 0;
      if (store) {
        const isCheckCutlery: null | boolean = $global.getState().isCheckCutlery;

        store.forEach((item: any) => {
          if (isCheckCutlery) {
            price += parseInt(item[priceName]) * parseInt(item[countName])
          } else {
            price += parseInt(item.sum)
          }
        })
      }

      return price
    },
    defaultAddress: (userInfoName: string, addressFields: string[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, data]: [any, any] = this.parseValue(userInfoName.replace('*', '}'), this.state.observeValues, {}, true);
      const fields: [string, string][] = JSON.parse(replaceAll(addressFields, ';', ','))
      let address: any = {};
      let indexSelected = -1;
      if (data) {
        updateGlobal({ idCart: data.id_cart.toString() })
        fields.forEach(([name, anothername]) => {
          address[anothername] = data[name]
        })

        // eslint-disable-next-line eqeqeq
        indexSelected = this.state.data?.findIndex((item: any) => item.value.id == address.id)

        if (indexSelected < 0) {
          const state: any = this.state;
          const anotherData: any[] = [...state.data, { title: address.street, value: address }];
          this.setState({ data: anotherData })
          indexSelected = anotherData.length - 1;
        }
      }

      const selected = $address.getState().selected;

      if (selected) {
        if (!this.state.data || (this.state.data && this.state.data[selected])) {
          return selected;
        }
      }

      if (!data) return 0

      return indexSelected;
    },
    toFixed: (value: any, length: any): any => parseFloat(value).toFixed(length),
    toJson: (value: any): string => JSON.stringify(value),
    pure: (dataPath: string) => {
      let data = parseString(this.parseValue(dataPath.replace('*', '}'), this.state.observeValues, {}, true)[1])

      return JSON.stringify(data)
    },
    findAddress: () => {
      return this.state.observeValues["cityName"]?.title;
    },
    findCity: (address: string, cities: string, key: string, observeValues: any = this.state.observeValues) => {
      let city: any = null;
      let find: any = null;

      //Ищем название города в строке улицы (старый вариант адресов)
      let title: string = observeValues[address]?.title;

      if (title) {
        [city] = title.split(', ')
        find = observeValues[cities]?.value?.find((_city: any) => _city.name.indexOf(city) !== -1);
        if (find) {
          return find[key]
        }
      }

      //Ищем название города в строке адреса, полученного по координатам (новый вариант адресов)
      title = observeValues["cityName"]?.title;
      if(title) {
        let [country, city] = title.split(', ');

        if (city === 'Московская область') {
          city = 'Москва'
        }

        if (city === 'Ленинградская область') {
          city = 'Санкт-Петербург'
        }

        if (city === 'Республика Татарстан (Татарстан)') {
          city = 'Казань'
        }

        find = observeValues[cities]?.value?.find((_city: any) => _city.name.indexOf(city) !== -1);

        if (find) {
          return find[key]
        }
      }
      return
    },
    getDateFromItemOrder: () => {
      const order = $order.getState();

      if (order[order.length - 1]) {
        return order[order.length - 1].date
      }
    },
    getTimeFromItemOrder: () => {
      const order = $order.getState();

      if (order[order.length - 1]) {
        return order[order.length - 1].time
      }
    },
    getIntervals: () => {
      if (this.state.observeValues.intervals) {
        return Object.keys(this.state.observeValues.intervals.value);
      }
    },
    getCalendarConditions: () => {
      if (this.state.observeValues.calendarConditions) {
        return JSON.stringify(this.state.observeValues.calendarConditions.value);
      }
    },
    sortBy: () => {
      return $sortBy.getState() ? 'id' : 'date'
    },
    sort: () => {
      return $sort.getState() ? 'desc' : 'asc'
    },
  }
  abortController?: AbortController;
  store: any;
  constructor(props: any) {
    super(props)
    this.state = {
      isPreloader: false,
      observeValues: {},
      waiting: false,
      externalChanged: false
    } as S

    this.store = combine({ detail: $detail, global: $global, order: $order, address: $address, cache: $cache })

    this.listeners = {};
  }

  getIntervals = (intervals: string) => {
    let [_intervals] = this.parseValue(intervals);
    if (_intervals) {
      _intervals = _intervals.split(',')
    }

    return _intervals
  }

  log(_log?: LogInterface, _value?: any) {
    let log: LogInterface | undefined = _log || this.props.log;
    const values = { ...this.state.observeValues };
    let debugParams: any = {};
    if (log) {
      const { cache, settings } = this.createSettings(log, values, _value);
      request({ method: "GET", ...settings, abortController: null }, () => { })

      if (log.debugParams) {
        let _cBody: any = log.debugParams;
        _cBody.forEach(({ name, value, useValue }: any) => {
          let _name: string = this.parseValue(name, values)[0] || name;
          if (useValue) {
            debugParams[_name] = this.parseValue(value, _value)[0] || _value;
          }
          else {
            debugParams[_name] = this.parseValue(value, values)[0] || value;
          }
        })
      }

      if (log.debug) {
        console.log({ cache, settings, debugParams })
      }
    }
  }

  transform = (value: any, transform: any) => {
    if (transform) {
      let _value: any;
      try {
        _value = JSON.parse(value)
      } catch (error) {
        _value = value;
      }

      value = _value.map((item: any) => {
        let newValueItem: any = {};

        Object.keys(transform).forEach((key) => {
          newValueItem[key] = item[transform[key]]
        })

        return newValueItem
      })
    }
    return value;
  }

  static recurseSelect(path: string[], prevSelected: any, isPath: boolean = false): any {
    const _path: string[] = [...path];
    if (path.length > 0) {
      const level: string = _path.shift() || '';
      if (path.length === 1) {
        if (isPath && prevSelected) return prevSelected[level];
        return returnValueOrNull(null)
      }
      else {
        if (prevSelected) {
          if (!prevSelected[level]) returnValueOrNull(prevSelected[level]);
          return ConstructorElement.recurseSelect(_path, prevSelected[level], true)
        }
        else {
          return returnValueOrNull(null)
        }
      }
    }
    return false
  }

  valueToHelper = (value: string): [any, any] => {
    if (!value || !value.replaceAll) return [value, undefined]
    return [value.replaceAll(REGEXEC, (full: any, name: any) => {
      if (name) {
        let funcAndArgs = name.split('(')
        if (funcAndArgs.length > 1) {
          let [func, args] = funcAndArgs;
          args = args.replace(')', '').split(/, |,/)
          if (this.helpers[func]) {
            return this.helpers[func](...args)
          }
        }
        else {
          if (this.helpers[name]) {
            return this.helpers[name]()
          }
        }
        return returnValueOrNull(null)
      }
      return full
    }), undefined];
  }

  valueFromData = (value: string, item: any, pureValue: any): [any, any] => {
    return [value.replaceAll(REGVAR, (full: any, name: any) => {
      if (name) {
        let path: [] = name.split('.');
        if (path.length > 1) {
          let recRes = ConstructorElement.recurseSelect(path, item);
          if (typeof recRes !== 'string' && typeof recRes !== 'number') {
            pureValue = recRes;
          }
          if (!recRes) return returnValueOrNull(recRes)
          return recRes
        }
      }
      if (item && typeof item[name] !== 'string' && typeof item[name] !== 'number') {
        pureValue = item[name];
      }

      if (!item) return returnValueOrNull(null)
      else if (!item[name]) return returnValueOrNull(item[name])
      return item[name]

    }), pureValue];
  }

  valueFromAllData = (value: string, item: any, pureValue: any): [any, any] => {
    pureValue = item
    return [value, pureValue]
    // REGALLDATA
  }

  valueFromState = (value: string, item: any, pureValue: any) => {
    let result: [any, any] = [value, pureValue]
    if (!value.replaceAll) return result

    let breakPureValue: any;
    let _value: string = value.replaceAll(REGSTATE, (full: any, name: any) => {
      let path: any[] = name.split('.');
      if (path.length > 1) {
        let recRes = ConstructorElement.recurseSelect(path, this.state);
        if (typeof recRes !== 'string' && typeof recRes !== 'number') {
          pureValue = recRes;
        }
        if (!recRes) return returnValueOrNull(recRes)
        return recRes
      }
      else {
        const state: any = { ...this.state }
        let recRes: any = state[path[0]];
        pureValue = recRes;
        breakPureValue = recRes;
      }

      return full;
    });

    if (breakPureValue) result = [JSON.stringify(breakPureValue), breakPureValue];
    else { result[0] = _value; }
    return result;
  }

  valueFromStore = (value: string, item: any, pureValue: any) => {
    let result: [any, any] = [value, pureValue]
    if (!value.replaceAll) return result
    let breakPureValue: any;
    let _value: string = value.replaceAll(REGSTORE, (full: any, name: any) => {
      if (name) {
        let path: any[] = name.split('.');
        if (path.length > 1) {
          let recRes = ConstructorElement.recurseSelect(path, this.store.getState());
          if (typeof recRes !== 'string' && typeof recRes !== 'number') {
            pureValue = recRes;
            breakPureValue = recRes;
          }
          return recRes
        }
        else {
          let recRes: any = this.store.getState()[path[0]];
          pureValue = recRes;
          breakPureValue = recRes;
        }
      }
      return full
    });

    if (breakPureValue) result = [JSON.stringify(breakPureValue), breakPureValue];
    else { result[0] = _value; }
    return result;
  }

  goToRecursivePath(path: string[], data: any): any {
    if (!path.length) return data
    let [first, ..._path] = path;

    let selected = data[first]

    return this.goToRecursivePath(_path, selected)
  }

  parseValue = (value: string, item?: any, pureValue?: any, isRecursiveSupport?: boolean): [any, any] => {
    let result: [any, any] = [value, undefined]

    const check = {
      RST: (v: any) => typeof v !== 'string' ? false : v.search(REGSTATE) !== -1,
      RS: (v: any) => typeof v !== 'string' ? false : v.search(REGSTORE) !== -1,
      RAD: (v: any) => typeof v !== 'string' ? false : v.search(REGALLDATA) !== -1,
      RE: (v: any) => typeof v !== 'string' ? false : v.search(REGEXEC) !== -1,
      RV: (v: any) => typeof v !== 'string' ? false : v.search(REGVAR) !== -1,
      RG: (v: any) => typeof v !== 'string' ? false : v.search(REGGET) !== -1,
      RP: (v: any) => typeof v !== 'string' ? false : v.search(REGPACKAGE) !== -1
    }
    if (check.RST(value)) result = this.valueFromState(value, item, pureValue);
    else if (check.RS(value)) result = this.valueFromStore(value, item, pureValue);
    else if (check.RAD(value)) result = this.valueFromAllData(value, item, pureValue);
    else if (check.RE(value)) result = this.valueToHelper(value)
    else if (check.RV(value)) result = this.valueFromData(value, item, pureValue);
    else if (check.RG(value)) result = [ValueFromGet(value), undefined];
    else if (check.RP(value)) result = [ValueFromPackage(value), undefined];

    if (isRecursiveSupport && (
      check.RST(result[0]) ||
      check.RS(result[0]) ||
      check.RAD(result[0]) ||
      check.RE(result[0]) ||
      check.RV(result[0]) ||
      check.RP(result[0])
    )) {
      result = this.parseValue(result[0], item, pureValue)
    }

    return result;
  }

  parseValueR(data: any, item: any) {
    let result: any = {}

    Object.keys(data).forEach((resName: string) => {
      let value = data[resName];
      if (typeof value === 'object') {
        result[resName] = this.parseValueR(value, item)
      }
      else {
        let res = this.parseValue(value, item);
        result[resName] = res[1] || res[0];
        result[resName] = parseString(result[resName])
      }
    })

    return result
  }

  formatData(root: string | undefined, data: any, item: any, parentName: string) {
    let result: any = {};
    let _item: any = item;

    if (root) {
      _item = item[root]
      if (data.join) {
        result = _item.map((group: any) => {
          return this.join(data, group)
        }).filter((item: any) => item && item)
      }
      else {
        result = _item.map((dish: any) => this.formatData(undefined, data, dish, ''))
      }
    }
    else {
      result = this.parseValueR(data, _item)
    }

    return result;
  }

  prepareData(root: string | undefined, type: 'fixed' | 'generator', data: any[] | any, item: any, resName: string) {
    let result: any;
    if (type === 'fixed') {
      result = data.map((_data: any) => this.formatData(root, _data, item, resName))
    }
    else {
      result = this.formatData(root, data, item, resName);
    }

    return result;
  }

  isSkip = (config: any, item: any): boolean => {
    let isSkip: boolean = false
    if (config.skip) {
      if (item) {
        config.skip.forEach((skip: any) => {
          Object.keys(skip).forEach(key => {
            if (item[key] === skip[key]) {
              isSkip = true
            }
          })
        })
      }
    }

    return isSkip
  }

  join = (config: any, item: any) => {
    let _item: any = {};


    if (config.skip) {
      item = Object.values(item).map((__item: any) => {
        if (this.isSkip(config, __item)) return false
        return __item;
      }).filter((it: any) => it && it)
    }

    if (config.join) {
      Object.keys(config.join).forEach((resName: string) => {
        let val = resName
        const element: JoinElementInterface = config.join[resName];
        const { root, type, data } = element;
        val = this.prepareData(root, type, data, item, resName);
        if (root) {
          _item[resName] = val
        }
        else {

          _item = val
        }
      })
    }

    return _item;
  }

  updateCache(config: requestInterface | undefined, data: any) {
    if (config?.cache) {
      let name: string = this.props.name || '';
      const values: any = { ...this.state.observeValues, ...data };
      const { settings } = this.createSettings(config, values)
      const nameSettings: string = name + JSON.stringify(settings)
      let _data: any = {
        [name]: data,
        [nameSettings]: data
      };
      updateCache(_data)
    }
  }

  requestThen(data: any, cb: Function | undefined, config: requestInterface | undefined) {
    this.updateCache(config, data);
    if (!data[0]) data = [data]


    let _data = Object.values(data).map((item: any, k, response: any) => {
      return this.join(config, item)
    }).filter((item: any) => item && item)


    if (cb) {
      cb(_data);
    }

    this.setState({ data: _data, error: undefined }, () => this.autoChange())
  }

  requestCatch(err: any) { }

  createSettings(config: requestInterface, values: any, _value?: any) {
    let url: string = ValueFromConfig(config.url)
    let cache: boolean | undefined = config.cache;
    if (this.props.observe) {
      let [pureValue, value] = this.parseValue(url, values)
      url = pureValue || value
    }
    else {
      url = this.parseValue(url, values)[0]
    }

    let settings: any = {};
    if (this.props.requestSettings) {
      settings = { ...this.props.requestSettings }
    }

    settings = { ...settings, url, method: config.method, eventName: config.eventName }

    if (config.params) {
      let params: any = {};
      settings.params = params;
      let _cParams: any = config.params;
      _cParams.forEach(({ name, value, useValue }: any) => {
        let _name: string = this.parseValue(name, values)[0] || name;
        if (useValue) {
          settings.params[_name] = this.parseValue(value, _value)[0] || _value;
        }
        else {
          settings.params[_name] = this.parseValue(value, values)[0] || value;
        }
      })
    }
    if (config.str_par) {
      let newArr: any = []
      config.str_par.map((e: any) => newArr.push('{[' + e.name + ']}' + '{[' + this.parseValue(e.value)[0] + ']}'))
      settings.params.str_par = newArr.join('')
    }

    if (config.body) {
      let body: any = {};
      settings.body = body;
      let _cBody: any = config.body;
      _cBody.forEach(({ name, value, useValue }: any) => {
        let _name: string = this.parseValue(name, values)[0] || name;
        if (useValue) {
          settings.body[_name] = this.parseValue(value, _value)[0] || _value;
        }
        else {
          settings.body[_name] = this.parseValue(value, values)[0] || value;
        }
      })
    }

    if (!settings.headers) {
      settings.headers = {};
    }

    if (config.headers) {
      settings.headers = { ...settings.headers, ...config.headers }
    }
    Object.keys(settings.headers).forEach((headerName) => {
      let _name: string = this.parseValue(headerName, values)[0] || headerName;
      let _header = ValueFromConfig(settings.headers[headerName]);
      settings.headers[_name] = this.parseValue(_header, values)[0] || _header;
    })

    if (!config.abortController) {
      this.abortController?.abort();
      this.abortController = new AbortController();
      settings.abortController = this.abortController;
    }

    let result: any = { cache, settings }
    return result
  }

  allRequereded = async (config: requestInterface | undefined, values: any) => {
    return new Promise((resolve) => {
      if (config && config?.required) {
        const { required }: any = config;
        Promise.all(required.map(({ value }: any) => {
          return new Promise((res) => {
            const [val] = this.parseValue(value, values)
            if (returnValueOrNull(val)) {
              res(true)
            }
            else {
              res(false)
            }
          })
        })).then((results) => {
          const hasError = results.findIndex((_val) => !_val);
          if (hasError !== -1)
            resolve(false)

          resolve(true)
        })
      }
      else {
        resolve(true)
      }
    })
  }

  _getData = async (
    cb: Function | undefined,
    config: requestInterface | undefined = this.props.request,
    _values: any = {},
    requestThen: Function = (data: any, cb: Function | undefined, config: requestInterface | undefined) => this.requestThen(data, cb, config),
    requestCatch: Function = (err: any) => this.requestCatch(err),
  ) => {
    const values: any = { ...this.state.observeValues, ..._values };
    if (config) {
      const { cache, settings } = this.createSettings(config, values);

      if (cache) {
        let cachedValue = $cache.getState()[this.props.name + JSON.stringify(settings)] || $cache.getState()[this.props.name];
        if (cachedValue) {
          requestThen(cachedValue, (...args: any[]) => {
            if (cb) {
              cb(...args)
            }
          }, config)
          return
        }
      }

      if (await this.allRequereded(config, values)) {
        this.setState({ waiting: true })

        request(settings, (isPreloader: boolean) => this.setState({ isPreloader }))
          .then((data: any) => requestThen(data, cb, config))
          .catch((err) => {
            debug(JSON.stringify({ settings, err }))
            requestCatch(err)
            this.setState({ data: [], error: err })
          })
          .then(() => {
            this.setState({ waiting: false })
          })
      }
    }
  }

  getData = (cb?: Function, config: requestInterface | undefined = this.props.request) => {
    return this._getData(cb, config)
  }

  observeGetDataCallback(data: any) {
    this.onChange(Array.isArray(data) ? data[0].value : data)
  }

  observeHandler = (name: string, e: CustomEvent) => {
    if (e.detail.value) {
      let value = JSON.parse(e.detail.value);
      if (name) {
        this.setState({ observeValues: { ...this.state.observeValues, [name]: value } }, () => {
          this.getData((data: any) => {
            this.reset()
            this.observeGetDataCallback(data)
            this.autoChange();
          })
        })
      }
    }
  }

  prepareObserve = (observe: any) => {
    let _observe: any = {};
    Object.keys(observe).forEach(name => {
      let [value, pureValue] = typeof observe[name] === 'string' ? this.parseValue(observe[name], {}) : [observe[name], undefined];
      let result: any = pureValue || value;
      _observe[name] = result
    })
    return _observe;
  }

  validateProc = () => { }

  autoChange() {
    // console.log('native', this.constructor.name)
  }

  componentDidMount() {
    if (this.props.name) window.CE.add(this.props.name, this)

    document.addEventListener(ConstructorElement.ValidateEvent, this.validateProc)
    if (this.props.observe) {
      Object.keys(this.props.observe).forEach((name: string) => {
        this.listeners[name] = (e: CustomEvent) => this.observeHandler(name, e)
        document.addEventListener(ConstructorElement.ChangeEvent + name, this.listeners[name])
      })
      this.setState({ observeValues: this.prepareObserve(this.props.observe) }, () => {
        this.getData().then(() => this.autoChange())
      })
    }
    else {
      this.getData().then(() => this.autoChange())
    }
  }

  externalUpdate(value: any) {
    this.onChange(value)
    return this
  }

  showExternalChanged(value?: any) {
    const { externalChanged } = this.state;
    if (externalChanged) clearTimeout(externalChanged)

    this.setState({
      externalChanged: setTimeout(() => {
        this.setState({ externalChanged: false })
      }, 3000)
    })
    return this;
  }

  componentWillUnmount() {
    if (this.props.name) window.CE.remove(this.props.name)
    this.abortController?.abort();
    document.removeEventListener(ConstructorElement.ValidateEvent, this.validateProc)
    Object.keys(this.listeners).forEach((name: string) => {
      document.removeEventListener(ConstructorElement.ChangeEvent + name, this.listeners[name])
    })
  }

  _onChange = (value: any) => {
    const { onChange } = this.props;
    if (onChange && typeof onChange === 'function') onChange(value);

    let name: string = ConstructorElement.ChangeEvent + this.props.name;
    let data: any = { value: JSON.stringify(value) };
    dispatchEvent(name, data)
  }

  onChange(value: any, key?: number) {
    this._onChange(value);
  }

  reset() {
    this.input?.reset()
  }

  _checkConditions = () => {
    let isValide = false
    const { condition } = this.props;
    if (!condition) isValide = true;
    else {
      let results: boolean[] = Object.keys(condition).map((name: string) => {
        const proc: ConditionProcInterface = condition[name];
        let [value, pureValue] = this.parseValue(proc.path, this.state.observeValues)
        let result: any = pureValue || value;
        if (result === 'null') result = null;
        if (result === 'undefined') result = undefined;
        if (result === 'false') result = false;

        if (proc.operation === 'equal') {
          if (result === 'true') result = true;
          if (result === 'false') result = false;
          if (proc.operand === '{}' || proc.operand === '[]') result = JSON.stringify(result)

          // eslint-disable-next-line eqeqeq
          return result == proc.operand
        }
        else if (proc.operation === 'notequal') {
          // eslint-disable-next-line eqeqeq
          return result != proc.operand
        }
        else if (proc.operation === 'larger') {
          return parseInt(result) > proc.operand
        }
        else if (proc.operation === 'less') {
          return parseInt(result) < proc.operand
        }
        else if (proc.operation === 'exist') {
          return Boolean(result)
        }
        return true
      })

      isValide = !Boolean(results.filter((_val) => !_val).length)
    }

    return isValide;
  }

  checkConditions() {
    return this._checkConditions()
  }

  renderPreloader() {
    return (
      <div className="w-full p-4 flex justify-center items-center">
        <div className="fa fa-spin fa-spinner text-black fa-2x" />
      </div>
    )
  }

  _renderError(error: any) {
    return (
      <div className="flex">
        <div className="ml-4 p-4 bg-red-600 rounded mr-auto">{error.message}</div>
      </div>
    )
  }

  renderError() {
    const error: any = this.state.error;
    const { showErrors } = this.props;
    let shows: ShowErrorInterface[] = showErrors || [{ type: "status", value: 404 }]

    if (error) {
      let shown = shows.find(({ type, value }) => error[type] === value)
      if (shown) return this._renderError(error)
      else return this.renderPreloader()
    }

    return false
  }

  public _render(children: any): JSX.Element {
    return (
      <div className={setClasses([], {
        hidden: !this.checkConditions(),
      })}>
        {this.renderError() || children}
      </div>
    )
  }

  public render(): JSX.Element {
    return <></>
  }

  static ChangeEvent = "constructorElementChangeEvent"
  static ValidateEvent = 'constructorElementValidateEvent'
}

export default ConstructorElement;
